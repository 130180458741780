<template>
  <div class="consent-page-wrapper">
    <div class="container">
      <h1>Согласие на обработку персональных данных</h1>

      Физическое лицо, заполняя формы на сайте way2wei.ru, действуя свободно,
      своей волей и в своем интересе, а также подтверждая свою дееспособность,
      предоставляет свое согласие на обработку персональных данных (далее –
      Согласие) Обществу с ограниченной ответственностью “Лаборатория
      эмоционального интеллекта” (ИНН 7703438337, connect@eilab.ru, +7 (495)
      971-73-02, адрес: 121205, г. Москва, вн. тер. г. муниципальный округ
      Можайский, тер. Сколково инновационного центра, б-р Большой, д. 42, стр.
      1, эт. 4, помещ. 88) (далее - Администратор), которому принадлежит
      веб-сайт way2wei.ru, на следующих условиях:<br />
      <div class="list">
        1. Персональные данные обрабатываются в соответствии с политикой в
        отношении обработки персональных данных (далее - Политика).<br />
        2. Согласие дается на обработку персональных данных как без
        использования средств автоматизации, так и с их использованием.<br />
        3. Цели обработки персональных данных:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;○ идентификация;<br />
        &nbsp;&nbsp;&nbsp;&nbsp;○ поддержание связи;<br />
        &nbsp;&nbsp;&nbsp;&nbsp;○ обеспечение работоспособности Системы;<br />
        &nbsp;&nbsp;&nbsp;&nbsp;○ статистические исследования<br />
        4. Для целей идентификации Администратор использует следующие
        Персональные данные: имя, адрес электронной почты, номер телефона.<br />
        5. Для целей исполнения обязательств и поддержания связи Администратор
        вправе использовать следующие Персональные данные: фамилия, имя,
        отчество, номер телефона, адрес электронной почты, дата и место
        рождения; данные и копию документа, удостоверяющего личность; адрес
        регистрации по месту жительства; адрес фактического проживания; документ
        об образовании, СНИЛС, гражданство.<br />
        6. Для целей обеспечения работоспособности Администратор использует иные
        Персональные данные: <br />
        &nbsp;&nbsp;&nbsp;&nbsp;(1) данные о технических средствах
        (устройствах): IP-адрес, вид операционной системы, тип браузера,
        географическое положение, поставщик услуг сети Интернет.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;(2) сведения о поведении Пользователя в Системе
        (в том числе дата, время и количество посещений, сведения о посещенных
        страницах, о переходе с других ресурсов, о направленных заявках).<br />
        &nbsp;&nbsp;&nbsp;&nbsp;(3) информация, автоматически получаемая при
        доступе к Системе, в том числе с использованием файлов cookies. Файлы
        cookies представляют собой фрагменты текста, который автоматически
        сохраняется в память интернет-браузера Пользователя. Это позволяет
        Системе в необходимых случаях обращаться к сохраненной информации на
        компьютере Пользователя и извлекать ее. Пользователь вправе изменить
        настройки своего интернет-браузера и отказаться от сохранения файлов
        cookies.
        <br />
        7. Для целей статистических исследований Администратор может
        использовать все перечисленные Персональные данные.<br />
        8. В ходе обработки с персональными данными будут совершены следующие
        действия: сбор; запись; систематизация; накопление; хранение; уточнение
        (обновление, изменение); извлечение; использование; передача
        (предоставление, доступ); блокирование; удаление; уничтожение.<br />
        9. Персональные данные обрабатываются до момента отзыва Согласия, до
        момента истечения срока их хранения или до тех пор, пока этого требуют
        цели обработки, в зависимости от того, что наступит раньше.<br />
        10. Согласие может быть отозвано путем направления Администратору
        заявления в порядке, предусмотренном Политикой.<br />
        11. В случае отзыва Согласия Администратор вправе продолжить обработку
        персональных данных без него при наличии оснований, указанных в
        Федеральном законе от 27.07.2006 № 152-ФЗ “О персональных данных”.
        <br />
        12. Согласие действует все время до момента прекращения обработки
        персональных данных, указанных в п. 6 и п. 7 Согласия.<br />
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  name: "consent",
  setup() {
    useHead({
      title: "Согласие на обработку персональных данных",
    });
  },
};
</script>

<style lang="scss" scoped>
.consent-page-wrapper {
  width: 100%;
  padding: 121px 0 50px;
  font-size: 17px;
  line-height: 27px;
  color: #414352;

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 1170px;
  }

  .list {
    margin-left: 10px;
    margin-top: 10px;
  }

  .head-text {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 28px;
    line-height: 1.2;
    margin: 30px 0 15px;
  }

  p {
    margin: 0 0 15px;
  }

  ul,
  ol {
    margin: 0 0 15px;
    padding: 0 0 0 40px;
  }

  @media (max-width: 1189px) {
    padding: 140px 20px 30px;

    .container {
      padding: 0 15px;
    }

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }

  @media (max-width: 764px) {
    padding: 80px 0 30px;
  }
}
</style>